import { defineStore } from 'pinia'

type SnackTypes = 'info' | 'error' | 'success'

type DynamicComponent = {
  value: boolean
  component: Component | undefined
  data: Object
  events: Object
}

interface State {
  snackBanners: {
    type: SnackTypes
    text: string
    id: number
  }[]
  dynamicPopup: DynamicComponent
  mobileHeaderFixed: boolean
}

const initDynamicComponent = () => ({
  value: false,
  component: undefined,
  data: {},
  events: {},
})

const initialSnackBanner = [] as State['snackBanners']

const useUIStore = defineStore('ui', {
  state: (): State => ({
    snackBanners: [...initialSnackBanner],
    dynamicPopup: initDynamicComponent(),
    mobileHeaderFixed: true,
  }),
  actions: {
    showSnackBanner(text: string = '', type: SnackTypes = 'success') {
      this.snackBanners.push({
        id: Date.now(),
        type,
        text: text || (type === 'success' ? 'Success' : 'Something went wrong'),
      })
    },
    showPopup(component: Component, data: Object = {}, events: Object = {}) {
      this.dynamicPopup = {
        value: true,
        component: markRaw(component),
        data,
        events,
      }
    },
    cleanPopup() {
      this.dynamicPopup = initDynamicComponent()
    },
    fixMobileHeader() {
      this.mobileHeaderFixed = true
    },
    unfixMobileHeader() {
      this.mobileHeaderFixed = false
    },
    resetSnackBanner(id: State['snackBanners'][number]['id']) {
      this.snackBanners = this.snackBanners.filter(item => item.id !== id)
    },
  },
  getters: {
    getDynamicPopup: ({ dynamicPopup }) => dynamicPopup,
  },
})

export default useUIStore
